import { Box, Flex } from "@atoms/Flex";
import { Icon } from "@atoms/Icon";
import { Text } from "@atoms/Text";
import { DarkTheme, GrayTheme, Punk2Theme, PunkTheme } from "@constants";
import { AVAILABLE_THEMES, Theme, useAppContext } from "@contexts/app";
import { useNotificationContext } from "@contexts/notification";
import { useOutsideAlerter } from "@hooks/useOutsidePress";
import { Button } from "@molecules/Button";
import { Toggle } from "@molecules/Toggle";
import { useRouting } from "expo-next-react-navigation";
import React, { ReactElement, useMemo, useRef, useState } from "react";
import { FlatList, ListRenderItem, Pressable } from "react-native";

import { ToastPosition } from "react-toastify";
import { useTheme } from "styled-components/native";

export const SettingsButton = ({
    onPress,
    isOpen,
}: {
    onPress: () => void;
    isOpen: boolean;
}) => {
    const theme = useTheme();
    const [isHovered, setIsHovered] = useState(false);
    return (
        <Pressable
            onPress={onPress}
            style={{
                justifyContent: "center",
            }}
        >
            <Box
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                style={{
                    width: 32,
                    height: 32,
                    borderRadius: theme.radius.medium,
                    backgroundColor: theme.color.rgba(
                        theme.color.white,
                        isOpen || isHovered ? 0.2 : 0.1
                    ),
                }}
                justify="center"
                align="center"
            >
                <Icon
                    name="settings"
                    size={16}
                    color={theme.color.rgba(
                        theme.color.white,
                        isOpen || isHovered ? 1 : 0.4
                    )}
                />
            </Box>
        </Pressable>
    );
};

const TradePanelBox = ({
    position,
    isActive,
}: {
    position?: "left" | "right";
    isActive: boolean;
}) => {
    const theme = useTheme();
    const [isHovered, setIsHovered] = useState(false);

    return (
        <Flex
            flex={1}
            direction={position === "right" ? "row-reverse" : "row"}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            style={{
                opacity: isActive ? 1 : isHovered ? 1 : 0.7,
                gap: theme.spacing.tiny,
                padding: theme.spacing.smallest,
                borderRadius: theme.radius.medium,
                borderWidth: 2,
                borderColor: theme.color.rgba(
                    theme.color.primary,
                    isActive ? 1 : 0
                ),
                backgroundColor: theme.color.rgba(theme.color.white, 0.05),
            }}
        >
            <Box
                color={
                    !isActive
                        ? theme.color.rgba(theme.color.white, 0.4)
                        : theme.color.rgba(theme.color.primary, 1)
                }
                flex={0.3}
                style={{
                    borderRadius: theme.radius.medium,
                }}
            />
            <Box
                color={theme.color.rgba(theme.color.white, 0.1)}
                flex={0.7}
                style={{
                    borderRadius: theme.radius.medium,
                }}
            />
        </Flex>
    );
};

const ToastBox = ({
    isActive,
    position,
    onPress,
}: {
    isActive: boolean;
    position: ToastPosition;
    onPress: (position: ToastPosition) => void;
}) => {
    const theme = useTheme();
    const [isHovered, setIsHovered] = useState(false);

    return (
        <Pressable
            onPress={() => !isActive && onPress(position)}
            style={{ flex: 1 }}
        >
            <Box
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                color={theme.color.rgba(theme.color.white, 0.1)}
                style={{
                    height: 50,
                    borderRadius: theme.radius.medium,
                    opacity: isActive ? 1 : isHovered ? 1 : 0.5,
                    padding: theme.spacing.smallest,
                    borderWidth: 2,
                    borderColor: theme.color.rgba(
                        theme.color.primary,
                        isActive ? 1 : 0
                    ),
                    alignItems:
                        position === "top-right" || position === "bottom-right"
                            ? "flex-end"
                            : "flex-start",
                    justifyContent:
                        position === "bottom-right" ||
                        position === "bottom-left"
                            ? "flex-end"
                            : "flex-start",
                }}
            >
                <Box
                    style={{
                        width: "50%",
                        height: "50%",
                        borderRadius: theme.radius.small,
                    }}
                    color={
                        isActive
                            ? theme.color.primary
                            : theme.color.rgba(theme.color.white, 0.4)
                    }
                />
            </Box>
        </Pressable>
    );
};

export const AppSettings = () => {
    const theme = useTheme();
    const { notify } = useNotificationContext();

    const {
        settings,
        toggleBoxedStyle,
        onChangeNotificationPosition,
        toggleColorVisionDeficiency,
        toggleBalance,
        toggleWallet,
    } = useAppContext();

    const onChangeToast = (position: ToastPosition) => {
        onChangeNotificationPosition(position);
        notify({
            title: "Notifications will appear here",
            description: "This is a sample notification",
        });
    };

    return (
        <SettingsList>
            <Box
                gap={theme.spacing.smaller}
                style={{
                    paddingHorizontal: theme.spacing.bigger,
                    paddingBottom: theme.spacing.bigger,
                }}
            >
                <Pressable onPress={toggleWallet}>
                    <Flex justify="space-between" align="center">
                        <Box gap={theme.spacing.bigger}>
                            <Text
                                smaller
                                selectable={false}
                                color={theme.color.rgba(theme.color.white, 0.6)}
                            >
                                Show Wallet
                            </Text>
                        </Box>

                        <Toggle
                            isActive={!settings.hideWallet}
                            onPress={toggleWallet}
                        />
                    </Flex>
                </Pressable>
                <Pressable onPress={toggleBalance}>
                    <Flex justify="space-between" align="center">
                        <Box gap={theme.spacing.bigger}>
                            <Text
                                smaller
                                selectable={false}
                                color={theme.color.rgba(theme.color.white, 0.6)}
                            >
                                Show Balance
                            </Text>
                        </Box>

                        <Toggle
                            isActive={!settings.hideBalance}
                            onPress={toggleBalance}
                        />
                    </Flex>
                </Pressable>
            </Box>

            <Box gap={theme.spacing.big}>
                <Flex align="center" justify="space-between">
                    <Text
                        small
                        selectable={false}
                        color={theme.color.rgba(theme.color.white, 0.6)}
                    >
                        Color
                    </Text>
                    <Text small semiBold capitalize>
                        {settings.theme.style}
                    </Text>
                </Flex>
                <ThemeSelect />
            </Box>

            <Pressable onPress={toggleColorVisionDeficiency}>
                <Flex justify="space-between" align="center">
                    <Text
                        small
                        selectable={false}
                        color={theme.color.rgba(theme.color.white, 0.6)}
                    >
                        Color Vision Deficiency
                    </Text>

                    <Toggle
                        isActive={settings.theme.colorDeficiency}
                        onPress={toggleColorVisionDeficiency}
                    />
                </Flex>
            </Pressable>

            <Box gap={theme.spacing.big}>
                <Flex align="center" justify="space-between">
                    <Text
                        small
                        selectable={false}
                        color={theme.color.rgba(theme.color.white, 0.6)}
                    >
                        Notification
                    </Text>
                    <Text small semiBold capitalize>
                        {settings.notifications?.position?.split("-").join(" ")}
                    </Text>
                </Flex>

                <Box
                    gap={theme.spacing.smaller}
                    style={{
                        borderRadius: theme.radius.medium,
                    }}
                >
                    <Flex gap={theme.spacing.smaller}>
                        <ToastBox
                            position="top-left"
                            onPress={onChangeToast}
                            isActive={
                                settings.notifications?.position === "top-left"
                            }
                        />
                        <ToastBox
                            position="top-right"
                            onPress={onChangeToast}
                            isActive={
                                settings.notifications?.position === "top-right"
                            }
                        />
                    </Flex>
                    <Flex gap={theme.spacing.smaller}>
                        <ToastBox
                            position="bottom-left"
                            onPress={onChangeToast}
                            isActive={
                                settings.notifications?.position ===
                                "bottom-left"
                            }
                        />
                        <ToastBox
                            position="bottom-right"
                            onPress={onChangeToast}
                            isActive={
                                settings.notifications?.position ===
                                "bottom-right"
                            }
                        />
                    </Flex>
                </Box>
            </Box>
        </SettingsList>
    );
};

export const PageSettings = () => {
    const theme = useTheme();
    const {
        settings,
        toggleShowHistory,
        toggleTradePanelLeft,
        toggleTradeOpenOrderModal,
        toggleBoxedStyle,
    } = useAppContext();

    return (
        <SettingsList>
            <Box
                style={{
                    padding: theme.spacing.bigger,
                    paddingBottom: 0,
                }}
            >
                <Text bold small selectable={false}>
                    Page Settings
                </Text>
            </Box>
            <Flex justify="space-between" align="center">
                <Text
                    small
                    selectable={false}
                    color={theme.color.rgba(theme.color.white, 0.6)}
                >
                    Theme
                </Text>
                <Flex gap={theme.spacing.smaller}>
                    <Button
                        text="Filled"
                        smallest
                        noBorder
                        stroke={settings?.theme?.boxed}
                        primary={!settings?.theme?.boxed}
                        onPress={toggleBoxedStyle}
                    />
                    <Button
                        text="Boxed"
                        smallest
                        noBorder
                        stroke={!settings?.theme?.boxed}
                        primary={settings?.theme?.boxed}
                        onPress={toggleBoxedStyle}
                    />
                </Flex>
            </Flex>
            <Pressable onPress={toggleShowHistory}>
                <Flex justify="space-between" align="center">
                    <Text
                        small
                        selectable={false}
                        color={theme.color.rgba(theme.color.white, 0.6)}
                    >
                        Recent Trades
                    </Text>

                    <Toggle
                        isActive={settings?.trade?.showHistory}
                        onPress={toggleShowHistory}
                    />
                </Flex>
            </Pressable>

            <Pressable onPress={toggleTradeOpenOrderModal}>
                <Flex justify="space-between" align="center">
                    <Text
                        small
                        selectable={false}
                        color={theme.color.rgba(theme.color.white, 0.6)}
                    >
                        Show Order Confirmation
                    </Text>

                    <Toggle
                        isActive={settings?.trade?.openOrderModal}
                        onPress={toggleTradeOpenOrderModal}
                    />
                </Flex>
            </Pressable>

            <Box gap={theme.spacing.big}>
                <Flex align="center" justify="space-between">
                    <Text
                        small
                        selectable={false}
                        color={theme.color.rgba(theme.color.white, 0.6)}
                    >
                        Trade Panel
                    </Text>
                    <Text small semiBold>
                        {settings.trade.panelLeft ? "Left" : "Right"}
                    </Text>
                </Flex>

                <Pressable onPress={toggleTradePanelLeft}>
                    <Box
                        style={{
                            borderRadius: theme.radius.medium,
                        }}
                    >
                        <Flex
                            justify="space-between"
                            style={{
                                height: 56,
                                gap: theme.spacing.smaller,
                            }}
                        >
                            <TradePanelBox
                                isActive={settings?.trade?.panelLeft}
                            />
                            <TradePanelBox
                                position="right"
                                isActive={!settings?.trade?.panelLeft}
                            />
                        </Flex>
                    </Box>
                </Pressable>
            </Box>
        </SettingsList>
    );
};

const ThemeSelect = () => {
    const theme = useTheme();

    const [isOpen, setIsOpen] = useState(false);

    const { settings, onChangeTheme } = useAppContext();

    const renderTheme: ListRenderItem<Theme> = useMemo(
        () =>
            ({ item, index }) => {
                const isActive = settings.theme.style == item;
                let backgroundColor = DarkTheme.color.black;
                if (Theme.PUNK === item)
                    backgroundColor = PunkTheme.color.black;
                if (Theme.GRAY === item)
                    backgroundColor = GrayTheme.color.black;
                if (Theme.BLUE === item)
                    backgroundColor = Punk2Theme.color.black;

                return (
                    <Pressable onPress={() => onChangeTheme(item)}>
                        <Box
                            align="center"
                            justify="center"
                            style={{
                                borderWidth: 2,
                                width: 58,
                                height: 28,
                                borderColor: theme.color.rgba(
                                    theme.color.primary,
                                    isActive ? 1 : 0
                                ),
                                backgroundColor,
                                borderRadius: theme.radius.medium,
                            }}
                        />
                    </Pressable>
                );
            },
        [onChangeTheme, settings, theme]
    );

    const toggleTheme = () => {
        setIsOpen(!isOpen);
    };

    const onClose = () => {
        setIsOpen(false);
    };

    const wrapperRef = useRef(null);

    useOutsideAlerter(wrapperRef, onClose);

    let backgroundColor = DarkTheme.color.black;
    if (Theme.PUNK === settings.theme.style)
        backgroundColor = PunkTheme.color.black;
    if (Theme.GRAY === settings.theme.style)
        backgroundColor = GrayTheme.color.black;
    if (Theme.BLUE === settings.theme.style)
        backgroundColor = Punk2Theme.color.black;

    return (
        <Box
            ref={wrapperRef}
            style={{
                // backgroundColor: theme.color.black,
                borderRadius: theme.radius.medium,
            }}
        >
            <FlatList
                horizontal
                data={AVAILABLE_THEMES}
                contentContainerStyle={{
                    gap: theme.spacing.smaller,
                }}
                renderItem={renderTheme}
            />
        </Box>
    );
};

const SettingsList = ({ children }: { children: ReactElement[] }) => {
    const theme = useTheme();

    return (
        <Box>
            {React.Children.map(children, (child, index) => {
                return React.cloneElement(child, {
                    style: {
                        borderTopWidth: 1,
                        borderColor: theme.color.rgba(
                            theme.color.white,
                            index === 0 ? 0 : 0.05
                        ),
                        padding: theme.spacing.bigger,
                    },
                });
            })}
        </Box>
    );
};
